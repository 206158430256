<script setup>
import { API } from "../model"
import { reactive } from "vue"
import { Avatar } from "../ui/Avatar";
import { Button } from "../ui/Button"
import { ICONS_VUE } from "../ui/iconsVue"
import { model } from "../model"
import "./CaseResponsables.scss"
import { useCurrentUserStore } from "../store/CurrentUserStore";

const props = defineProps(["case", "user", "scope"])

const data = reactive({
    user: props.user,
    caseId: props.case,
    scopeId: props.scope,
    response: null,
    remove: null
})
const userStore = useCurrentUserStore
const { related_users } = model.case

const handleClick = async () => {
    try {
        if (data.caseId && data.user["id"]) {
            const response = await API.requestCaseUser({user_ids: [parseInt(data.user["id"])], id:parseInt(data.caseId), is_add_users:true})
            data.remove = null
            data.response = response.user
        }
    } catch (error) {
        console.log(error)
    }
}

const removeUser = async () => {
    try {
        if (data.user) {
            const response = await API.removeCaseUser({user_ids: [], id:parseInt(data.caseId), is_add_users:false})
            data.remove = response
        }
    } catch (error) {
        console.log(error)
    }
}

const isCurrentUserPresent = () => {
    return related_users.some(v => v?.id === data.user.id)
}

const RW = userStore.hasPermissions("cases", "write_private")
</script>

<template>
    <div className="box-pastilles">
        <div class="pastilles">
            <div v-if="RW && (!data.response && !isCurrentUserPresent()) || data.remove">
                <Button @click="handleClick">Rejoindre</Button>
            </div>
            <div v-else>
                <div v-if="data.response" class="pastille-box">
                    <Avatar :user="data.response" :user-id="data.user.id"/>
                    <span class="cross-icons" @click="removeUser" v-html="ICONS_VUE['cross']"></span>
                </div>
                <div v-else-if="!data.response" class="pastille-box">
                    <Avatar :user="data.user" :user-id="data.user.id"/>
                    <span class="cross-icons" @click="removeUser" v-html="ICONS_VUE['cross']"></span>
                </div>
            </div>
            <div v-for="(resp, index) in related_users" :key="index">
                <div class="pastille">
                    <div v-if="resp.id !== data.user.id">
                        <Avatar :user="resp" :user-id="data.user.id"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>