<script setup>
import "./SettingsShuffleWorkflows.scss"
import { model, API, getMembers } from "../../model"
import { reactive, ref } from "vue"
import { t } from "../../i18n/index"
import { h } from "vue"
import { watchEffect } from "vue"
import { Spinner } from "../../ui/Spinner"
import { DataTable } from "../../ui/DataTable"
import { Button } from "../../ui/Button"
import { Input } from "../../ui/Input"
import { FilteredDropdown } from "../../ui/FilteredDropdown"

const data = reactive({
    authentications: null,
    user: null,
    errors: null
})

watchEffect(async () => {
    data.authentications = await API.getShuffleAuthentications(model.scopeId)
})

const add = async () => {
    const result = await API.addShuffleAuth(+model.scopeId, data.user.id)
    if (!result.success) {
        data.errors = result.errors
    }
    else {
        data.authentications = await API.getShuffleAuthentications(model.scopeId)
        data.errors = null
    }
}
const queryUsers = filter => getMembers({ search: filter })
</script>

<template>
    <div id="shuffle-settings">
        <h1>Shuffle authentications</h1>
        <div v-if="data.authentications">
            <div class="section">
                <h2>Authentications</h2>
                <DataTable :noSearch="true" :data="data.authentications.items" :columns="[
                    { title: t('Name'), type: 'field', render: x => x.name },
                    { title: t('App Name'), type: 'field', render: x => x.app_name },
                    { title: t('App Version'), type: 'field', render: x => x.app_version },
                    { title: t('Created'), type: 'date', render: x => x.created_at },
                    { title: t('Updated'), type: 'date', render: x => x.updated_at },
                    { title: t('Fields'), type: 'field', render: x => x.fields?.join(', ') }
                ]" />
            </div>
            <div class="section">
                <h2>Add a new Authentication</h2>
                <p>
                    {{ t("User") }}
                    <FilteredDropdown menuClass='choose-new-member' :query="queryUsers" :onSelect="u => data.user = u"
                        :button="data.user?.display_name || t('Select user...')" :render="u => u.display_name" />
                    <Button @click="add()">{{ t('Add new auth') }}</Button>
                </p>
                <p v-if="data.errors">
                    Failure:
                <ul>
                    <li v-for="error in data.errors">
                        {{ error }}
                    </li>
                </ul>
                </p>
            </div>
        </div>
    </div>
</template>